import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContent from '../views/AppContent.vue'
import IndexView from '../views/IndexView.vue'
import ViewContent from '../views/ViewContent.vue'
import SingleViewContent from '../views/SingleViewContent.vue'

// import MaintanceLogCreateContent from '../views/maintance-logs/MaintanceLogCreateContent.vue'
// import MaintanceLogEditContent from '../views/maintance-logs/MaintanceLogEditContent.vue'
import CheckListCreateContent from '../views/check-lists/CheckListCreateContent.vue'
import CheckListEditContent from '../views/check-lists/CheckListEditContent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexView',
    component: IndexView,
    meta: {
      title: 'PACH Supervisión'
    }
  },
  {
    path: '/auth',
    name: 'AuthContent',
    component: SingleViewContent,
    children: [
      {
        path: '/auth/login',
        name: 'LoginContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'LoginView',
            component: () => import(/* webpackChunkName: "auth--login" */ '../views/auth/login/LoginView.vue'),
            meta: {
              title: 'Login',
              auth: true
            }
          }
        ]
      },
      {
        path: '/auth/logout',
        name: 'LogoutContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'LogoutView',
            component: () => import(/* webpackChunkName: "auth--logout" */ '../views/auth/logout/LogoutView.vue'),
            meta: {
              title: 'Logout'
            }
          }
        ]
      },
      {
        path: '/auth/reset-passwd',
        name: 'ResetPasswdContent',
        component: SingleViewContent,
        children: [
          {
            path: '/',
            name: 'ResetPasswdView',
            component: () => import(/* webpackChunkName: "auth--reset-passwd" */ '../views/auth/reset-passwd/ResetPasswdView.vue'),
            meta: {
              title: 'Restablecer contraseña'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    name: 'AppContent',
    component: AppContent,
    redirect: '/check-lists',
    children: [
      // {
      //   path: '/dashboard',
      //   name: 'DashboardContent',
      //   component: ViewContent,
      //   children: [
      //     {
      //       path: '/',
      //       name: 'DashboardView',
      //       component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/DashboardView.vue'),
      //       meta: {
      //         title: 'Dashboard'
      //       }
      //     }
      //   ]
      // },
      {
        path: '/check-lists',
        name: 'CheckListsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'CheckListsView',
            component: () => import(/* webpackChunkName: "check-lists" */ '../views/check-lists/CheckListsView.vue'),
            meta: {
              title: 'Listas de verificación'
            }
          },
          // {
          //   path: '/check-lists/create',
          //   name: 'CheckListCreateView',
          //   component: () => import(/* webpackChunkName: "check-lists--create" */ '../views/check-lists/CheckListCreateView.vue'),
          //   meta: {
          //     title: 'Crear lista'
          //   }
          // },
          // {
          //   path: '/check-lists/edit/:id?',
          //   name: 'CheckListEditView',
          //   component: () => import(/* webpackChunkName: "check-lists--edit" */ '../views/check-lists/CheckListEditView.vue'),
          //   meta: {
          //     title: 'Modificar lista'
          //   }
          // },
          {
            path: '/check-lists/create',
            name: 'CheckListCreateContent',
            component: CheckListCreateContent,
            children: [
              {
                path: '/',
                name: 'CheckListCreateView',
                component: () => import(/* webpackChunkName: "check-lists--create" */ '../views/check-lists/CheckListCreateView.vue'),
                meta: {
                  title: 'Crear lista'
                }
              },
              {
                path: '/check-lists/create/answers/:id?',
                name: 'CheckListCreateAnswersView',
                component: () => import(/* webpackChunkName: "check-lists--create-answers" */ '../views/check-lists/CheckListCreateAnswersView.vue'),
                meta: {
                  title: 'Crear lista'
                }
              }
            ]
          },
          {
            path: '/check-lists/edit',
            name: 'CheckListEditContent',
            component: CheckListEditContent,
            children: [
              {
                path: '/check-lists/edit/:id',
                name: 'CheckListEditView',
                component: () => import(/* webpackChunkName: "check-lists--edit" */ '../views/check-lists/CheckListEditView.vue'),
                meta: {
                  title: 'Modificar lista'
                }
              },
              {
                path: '/check-lists/edit/answers/:id',
                name: 'CheckListEditAnswersView',
                component: () => import(/* webpackChunkName: "check-lists--edit-answers" */ '../views/check-lists/CheckListEditAnswersView.vue'),
                meta: {
                  title: 'Modificar lista'
                }
              }
            ]
          },
          {
            path: '/check-lists/request/:id/:folio',
            name: 'CheckListRequestAuthView',
            component: () => import(/* webpackChunkName: "check-lists--request-auth" */ '../views/check-lists/CheckListRequestAuthView.vue'),
            meta: {
              title: 'Solicitar autorización'
            }
          },
          {
            path: '/check-lists/view/:id',
            name: 'CheckListView',
            component: () => import(/* webpackChunkName: "check-lists--view" */ '../views/check-lists/CheckListDetailsView.vue'),
            meta: {
              title: 'Detalle de la lista'
            }
          }
        ]
      },
      {
        path: '/maintance-logs',
        name: 'MaintanceLogsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'MaintanceLogsView',
            component: () => import(/* webpackChunkName: "maintance-logs" */ '../views/maintance-logs/MaintanceLogsView.vue'),
            meta: {
              title: 'Bitácoras de mantenimiento'
            }
          },
          {
            path: '/maintance-logs/create',
            name: 'MaintanceLogCreateView',
            component: () => import(/* webpackChunkName: "maintance-logs--create" */ '../views/maintance-logs/MaintanceLogCreateView.vue'),
            meta: {
              title: 'Crear bitácora'
            }
          },
          {
            path: '/maintance-logs/edit/:id',
            name: 'MaintanceLogEditView',
            component: () => import(/* webpackChunkName: "maintance-logs--edit" */ '../views/maintance-logs/MaintanceLogEditView.vue'),
            meta: {
              title: 'Modificar bitácora'
            }
          },
          // {
          //   path: '/maintance-logs/create',
          //   name: 'MaintanceLogCreateContent',
          //   component: MaintanceLogCreateContent,
          //   children: [
          //     {
          //       path: '/',
          //       name: 'MaintanceLogCreateView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--create" */ '../views/maintance-logs/MaintanceLogCreateView.vue'),
          //       meta: {
          //         title: 'Crear lista'
          //       }
          //     },
          //     {
          //       path: '/maintance-logs/create/complete',
          //       name: 'MaintanceLogCreateView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--create-complete" */ '../views/maintance-logs/MaintanceLogCreateCompleteView.vue'),
          //       meta: {
          //         title: 'Crear lista'
          //       }
          //     }
          //   ]
          // },
          // {
          //   path: '/maintance-log/edit',
          //   name: 'MaintanceLogEditContent',
          //   component: MaintanceLogEditContent,
          //   children: [
          //     {
          //       path: '/',
          //       name: 'MaintanceLogEditView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--edit" */ '../views/maintance-logs/MaintanceLogEditView.vue'),
          //       meta: {
          //         title: 'Modificar bitácora'
          //       }
          //     },
          //     {
          //       path: '/maintance-log/edit/complete',
          //       name: 'MaintanceLogEditCompleteView',
          //       component: () => import(/* webpackChunkName: "maintance-logs--edit-complete" */ '../views/maintance-logs/MaintanceLogEditCompleteView.vue'),
          //       meta: {
          //         title: 'Modificar bitácora'
          //       }
          //     }
          //   ]
          // },
          {
            path: '/maintance-logs/request/:id/:folio',
            name: 'MaintanceLogRequestAuthView',
            component: () => import(/* webpackChunkName: "maintance-logs--request-auth" */ '../views/maintance-logs/MaintanceLogRequestAuthView.vue'),
            meta: {
              title: 'Solicitar autorización'
            }
          },
          {
            path: '/maintance-logs/view/:id',
            name: 'MaintanceLogDetailsView',
            component: () => import(/* webpackChunkName: "maintance-logs--view" */ '../views/maintance-logs/MaintanceLogDetailsView.vue'),
            meta: {
              title: 'Detalle de la bitácora'
            }
          }
        ]
      },
      {
        path: '/maintances',
        name: 'MaintancesContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'MaintancesView',
            component: () => import(/* webpackChunkName: "maintances" */ '../views/maintances/MaintancesView.vue'),
            meta: {
              title: 'Mantenimientos'
            }
          },
          {
            path: '/maintances/create',
            name: 'MaintanceCreateView',
            component: () => import(/* webpackChunkName: "maintances--create" */ '../views/maintances/MaintanceCreateView.vue'),
            meta: {
              title: 'Crear mantenimiento'
            }
          },
          {
            path: '/maintances/edit',
            name: 'MaintanceEditView',
            component: () => import(/* webpackChunkName: "maintances--edit" */ '../views/maintances/MaintanceEditView.vue'),
            meta: {
              title: 'Modificar mantenimiento'
            }
          },
          {
            path: '/maintances/view',
            name: 'MaintancDetailsView',
            component: () => import(/* webpackChunkName: "maintances--view" */ '../views/maintances/MaintanceDetailsView.vue'),
            meta: {
              title: 'Detalle del mantenimiento'
            }
          }
        ]
      },
      {
        path: '/threads',
        name: 'ThreadsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'ThreadsView',
            component: () => import(/* webpackChunkName: "threads" */ '../views/threads/ThreadsView.vue'),
            meta: {
              title: 'Indicencias'
            }
          },
          {
            path: '/threads/create',
            name: 'ThreadCreateView',
            component: () => import(/* webpackChunkName: "threads--create" */ '../views/threads/ThreadCreateView.vue'),
            meta: {
              title: 'Crear incidencia'
            }
          },
          {
            path: '/threads/edit',
            name: 'ThreadEditView',
            component: () => import(/* webpackChunkName: "threads--edit" */ '../views/threads/ThreadEditView.vue'),
            meta: {
              title: 'Modificar incidencia'
            }
          },
          {
            path: '/threads/view',
            name: 'ThreadDetailsView',
            component: () => import(/* webpackChunkName: "threads--view" */ '../views/threads/ThreadDetailsView.vue'),
            meta: {
              title: 'Detalle de la incidencia'
            }
          }
        ]
      },
      {
        path: '/stadistics',
        name: 'StadisticsContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'StadisticsView',
            component: () => import(/* webpackChunkName: "stadistics" */ '../views/stadistics/StadisticsView.vue'),
            meta: {
              title: 'Estadísticas'
            }
          }
        ]
      },
      {
        path: '/account',
        name: 'AccountContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'AccountView',
            component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountView.vue'),
            meta: {
              title: 'Mi cuenta'
            }
          },
          {
            path: '/account/profile',
            name: 'ProfileView',
            component: () => import(/* webpackChunkName: "account--profile" */ '../views/account/ProfileView.vue'),
            meta: {
              title: 'Mi perfil'
            }
          }
        ]
      },
      {
        path: '/help',
        name: 'HelpContent',
        component: ViewContent,
        children: [
          {
            path: '/',
            name: 'HelpView',
            component: () => import(/* webpackChunkName: "help" */ '../views/help/HelpView.vue'),
            meta: {
              title: 'Ayuda'
            }
          },
          {
            path: '/help/get-support',
            name: 'GetSupport',
            component: () => import(/* webpackChunkName: "help--get-support" */ '../views/help/GetSupportView.vue'),
            meta: {
              title: 'Obtener asistencia'
            }
          },
          {
            path: '/help/feedback',
            name: 'FeedBack',
            component: () => import(/* webpackChunkName: "help--feedback" */ '../views/help/FeedBackView.vue'),
            meta: {
              title: 'Enviar comentarios'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/welcome/WelcomeView.vue'),
    meta: {
      title: 'Le damos la bienvenida'
    }
  },
  {
    path: '*',
    name: 'ErrorView',
    component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
    meta: {
      title: 'Oops'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  saveScrollPosition: false,
  transitionOnLoad: true,
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
