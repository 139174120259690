<template>
  <div></div>
</template>

<script>
// Router
// import ViewContent from '../views/ViewContent.vue'

// Mixins
import { defines } from '@/mixins/config'

export default {
  name: 'IndexView',
  mixins: [defines],
  components: { },
  data: () => ({}),
  methods: { },
  watch: { },
  computed: { },
  mounted () {
    const auth = localStorage.getItem('supervisionapp')

    if (auth) {
      this.$router.push('/check-lists')
    } else {
      this.$router.push('/auth/login')
    }
  },
  created () {},
  destroyed () { }
}

</script>
