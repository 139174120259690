<template>
  <v-bottom-sheet
    v-model="visibility"
    overlay-color="darkGrey"
    overlay-opacity="0.6"
    class="rounded-t-lg"
    width="100%"
    inset>
    <v-card
      class="pt-6 pb-4 px-6 rounded-t-lg rounded-b-0"
      flat>
      <v-row
        justify="center">
        <v-col
          xl="12" lg="12" md="12"
          cols="12">
          <v-card-text
            class="text-center pt-0 pb-2 px-0">
            <v-icon
              :color="color"
              class="mb-4"
              size="46">
              {{ icon }}
            </v-icon>
            <span
              class="text-h6 d-block font-weight-bold mb-1">
              {{ title }}
            </span>
            <p class="mb-0">
              {{ text }}
            </p>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        class="justify-center">
        <v-col
          class="px-2"
          cols="12">
          <v-btn
            @click.prevent="toggle"
            class="rounded-lg"
            color="primary"
            outlined
            large
            block>
            Aceptar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'BottomSheetComponent',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    visibility: false,
    timeout: 6000
  }),
  methods: {
    toggle () {
      this.visibility = !this.visibility
    }
  }
}

</script>
