<template>
  <div>
    <div
      class="app-section">
      <v-container
        fluid>
        <v-row
          class="align-center">
          <v-col
            xl="7" lg="7" md="10" sm="12">
            <div
              class="app-section--text with-back">
              <v-btn
                href="#/check-lists"
                class="is-back"
                small
                icon
                fab>
                <v-icon>
                  arrow_back
                </v-icon>
              </v-btn>
              <h1
                class="text-h5 font-weight-semibold mb-0">
                Modificar lista
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <transition name="fade">
      <router-view
        @sheet="toggleSheet"
        @snack="toggleSnack"
        @loading="toggleLoading"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CheckListEditContent',
  data: () => ({ }),
  methods: {
    toggleLoading (loading) {
      this.$emit('loading', loading)
    },

    toggleSheet (sheet) {
      this.$emit('sheet', sheet)
    },

    toggleSnack (snack) {
      this.$emit('snack', snack)
    }
  },
  watch: { },
  computed: { },
  mounted () { },
  created () { },
  destroyed () { }
}
</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .27s;
}

.fade-enter-active {
  transition-delay: .27s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  /* transform: translateY(10px); */
}

</style>
