<template>
  <v-navigation-drawer
    v-model="drawer"
    :color="$vuetify.breakpoint.mobile ? '' : ''"
    :bottom="$vuetify.breakpoint.mobile ? true : false"
    :width="$vuetify.breakpoint.mobile ? '100%' : '550px'"
    :class="['app-notifications', $vuetify.breakpoint.mobile ? 'rounded-t-lg' : '']"
    mobile-breakpoint="600"
    overlay-opacity="0.6"
    overlay-color="black"
    touchless
    temporary
    floating
    fixed
    right
    app>
    <template v-slot:[`prepend`]>
      <v-toolbar
        color="transparent"
        height="70"
        tag="div"
        flat>
        <v-toolbar-title
          class="is-close">
          <span
            class="proxima-nova font-weight-semibold"
            style="font-size: 1.1rem !important;">
            Notificaciones
            <v-chip
              v-if="totalItems > 0"
              color="primary text-body-2"
              class="ml-2">
              {{ totalItems }}
            </v-chip>
          </span>
          <v-btn
            @click="close"
            width="38"
            height="38"
            icon>
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <v-sheet
      v-if="loading"
      :color="$vuetify.theme.dark ? '#363636' : 'white'"
      class="d-flex justify-center align-center text-center fill-height is-loading">
      <v-progress-circular
        :color="!$vuetify.theme.dark ? 'primary' : 'grey lighten-5'"
        class="mr-3"
        size="28"
        width="2"
        indeterminate>
      </v-progress-circular>
    </v-sheet>
    <div
      v-if="items.length > 0">
      <vuescroll
        :ops="ops">
        <v-list
          :nav="!$vuetify.breakpoint.mobile"
          two-line
          dense>
          <v-list-item
            v-for="item in items"
            :href="path[item.type] + item.id_item"
            :key="item.id_notification"
            :class="[active[item.is_read], !$vuetify.breakpoint.mobile ? 'rounded-lg overflow-hidden' : '']"
            link>
            <v-list-item-avatar>
              <v-avatar
                :color="!$vuetify.theme.dark ? item.color : 'grey darken-3'"
                size="38">
                <v-icon
                  color="white"
                  size="24">
                  {{ item.icon }}
                </v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                class="proxima-nova font-weight-semibold"
                style="font-size: 0.875rem !important;">
                {{ item.title }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="font-weight-regular">
                {{ item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>
                {{ item.date }}
              </v-list-item-action-text>
              <v-btn
                width="24"
                height="24"
                icon>
                <v-icon>
                  chevron_right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <!-- <v-divider
            v-for="index in items"
            :key="index"></v-divider> -->
        </v-list>
      </vuescroll>
    </div>
    <v-row
      v-else
      class="fill-height"
      justify="center"
      align="center">
      <v-col
        class="text-center"
        cols="12">
        <v-img
          :src="APP_IMG_CONTENT + 'asset15.png'"
          :width="!$vuetify.breakpoint.mobile ? '300px' : '70%'"
          class="mx-auto mb-10"
          contain>
        </v-img>
        <span
          class="text-h6 font-weight-regular">
          Nada por aquí...
        </span>
        <p
          class="mb-0">
          Le avisaremos cuando haya algo nuevo
        </p>
      </v-col>
    </v-row>
    <!-- <template v-slot:[`append`]>
      <v-divider
        v-if="items.length > 0"
        class="border-dashed">
      </v-divider>
      <v-list
        v-if="items.length > 0"
        dense
        nav>
        <v-list-item
          class="primary--text text-center"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Marcar como leídas
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
// Plugins
import axios from 'axios'
import vuescroll from 'vuescroll'

// Mixins
import { defines, layout, session, theme } from '@/mixins/config'

export default {
  name: 'NotificationsComponent',
  mixins: [defines, layout, theme, session],
  components: { vuescroll },
  data: () => ({
    app: false,
    drawer: false,
    subdrawer: false,
    totalItems: 0,
    items: [],
    form: {
      module: 'notifications',
      request: 'get',
      supervisorID: null
    },
    ops: {
      vuescroll: {
        wheelScrollDuration: 250
      },
      scrollPanel: {
        speed: 0,
        scrollingX: false,
        easing: 'easeInQuint',
        initialScrollX: false
      },
      rail: {
        background: 'transparent',
        opacity: 5,
        size: '3px',
        specifyBorderRadius: false
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#022958', // #022958
        opacity: 0.4,
        hoverStyle: false,
        specifyBorderRadius: false,
        minSize: 0.2,
        size: '3px',
        disable: false
      }
    },
    path: {
      checklist: '#/check-lists/view/',
      maintancelog: '#/maintance-logs/view/',
      maintance: '',
      thread: ''
    },
    type: {
      color: {
        check_list: 'warning',
        maintance_log: 'accent',
        maintance: '',
        thread: ''
      },
      icon: {
        checklist: 'checklist',
        maintancelog: 'assignment',
        maintance: '',
        thread: ''
      }
    },
    active: {
      0: 'v-list-item--active',
      1: ''
    },
    loading: false,
    snack: {
      text: ''
    }
  }),
  methods: {
    open () {
      this.drawer = !this.drawer
      this.subdrawer = !this.subdrawer

      this.getNotifications()
    },

    close () {
      this.drawer = !this.drawer
      this.subdrawer = !this.subdrawer
    },

    // Get Notifications
    async getNotifications () {
      this.loading = true

      try {
        await axios
          .get(this.API + 'NotificationsAjax.php', {
            params: {
              module: this.form.module,
              request: 'get',
              supervisorID: this.auth.supervisorID
            }
          })
          .then(response => {
            // console.log(response.data)

            this.loading = false

            if (response.data.status) {
              this.totalItems = response.data.total
              this.items = response.data.items
            } else {
              this.snack.text = response.data.snack.text
              this.$emit('snack', this.snack)
            }
          })
          .catch(e => {
            this.loading = false

            this.snack.text = e
            this.$emit('snack', this.snack)
          })
      } catch (e) {
        this.loading = false

        this.snack.text = e
        this.$emit('snack', this.snack)
      }
    },

    // Update Notification
    async updateNotification () {}
  },
  watch: { },
  computed: { },
  mounted () {
    this.form.supervisorID = this.auth.supervisorID
  },
  created () { },
  destroyed () { }
}

</script>
