<template>
  <div>
    <v-dialog
      v-model="show"
      transition="slide-y-transition"
      overlay-color="darkGrey"
      overlay-opacity="0.6"
      width="550"
      scrollable
      persistent>
      <v-card
        class="about-dialog"
        flat>
        <v-card-title
          class="is-close">
          Acerca de
          <v-btn
            @click="toggle"
            icon>
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          class="text-center pb-6">
          <div
            class="brand d-flex justify-center align-center mb-3">
            <span
              :class="['brand--icon', !$vuetify.theme.dark ? 'primary--text' : 'grey--text text--lighten-4']">
              <v-img
                :src="!$vuetify.theme.dark ? APP_IMG_BRAND + 'pach.svg' : APP_IMG_BRAND + 'pach-light.svg'"
                class="mx-auto"
                width="100px">
              </v-img>
            </span>
            <!-- <v-divider
              class="mx-5"
              vertical>
            </v-divider>
            <span
              :class="['brand--text text-h5 mt-1', !$vuetify.theme.dark ? 'text--primary' : 'grey--text text--lighten-4']">
              Supervisión
            </span> -->
          </div>
          <p
            class="mb-5">
            <span
              class="d-block text--secondary mb-5">
              Versión: 1.0.0
            </span>
            <span
              class="d-block text--primary font-weight-semibold">
              ¿Quíere dejar algún comentario o ha encontrado algún error? Comuníquenoslo
            </span>
          </p>
          <v-btn
            @click="toggle"
            href="#/help/feedback"
            class="mb-10"
            depressed>
            Enviar comentarios
          </v-btn>
          <p
            class="text-body-2 text--secondary font-weight-medium mb-0">
            Copyright &copy; 2022 PACH Administración, SA de CV.
            <!-- <i class="material-icons">code</i> with <i class="material-icons danger--text">favorite</i> by Blixsoft Technologies. -->
            <!-- <a
              href="https://www.blixsoft.com/legal/end-user-agreement"
              class="primary--text text-underline-hover"
              target="_blank">
              Acuerdo de licencia de usuario final
            </a> -->
          </p>
        </v-card-text>
        <!-- <v-card-actions
          class="justify-end">
          <v-btn
            @click="toggle"
            :class="$vuetify.theme.dark ? 'body--text' : ''"
            color="primary"
            depressed>
            Aceptar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Mixins
import { defines } from '@/mixins/config'

export default {
  name: 'AboutComponent',
  mixins: [defines],
  components: { },
  data: () => ({
    show: false
  }),
  methods: {
    toggle () {
      this.show = !this.show
    }
  }
}

</script>
