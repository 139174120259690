<template>
  <v-navigation-drawer
    v-model="drawer"
    :app="app"
    :mini-variant-width="miniWidth"
    :width="$vuetify.breakpoint.mobile ? '290px' : width"
    :floating="$vuetify.breakpoint.mobile ? true : true"
    :color="$vuetify.breakpoint.mobile ? '' : ''"
    :temporary="$vuetify.breakpoint.mobile ? true : true"
    :permanent="$vuetify.breakpoint.mobile ? false : false"
    :mini-variant="$vuetify.breakpoint.mobile ? false : mini"
    :hide-overlay="$vuetify.breakpoint.mobile ? false : true"
    :class="mini ? 'app-sidebar float' : 'app-sidebar float expanded'"
    :expand-on-hover="$vuetify.breakpoint.mobile ? false : expandOnHover"
    mobile-breakpoint="600"
    overlay-opacity="0.6"
    overlay-color="black"
    tag="aside"
    clipped-left
    fixed>
    <template v-slot:[`prepend`]>
      <v-toolbar
        :height="$vuetify.breakpoint.mobile ? '70' : toolbar.height"
        class="px-0"
        tag="div"
        outlined
        flat>
        <v-toolbar-title>
          <div class="brand d-flex align-center">
            <div
              class="brand--icon">
              <v-img
                :src="!$vuetify.breakpoint.mobile ? APP_IMG_BRAND + 'pach.svg' : APP_IMG_BRAND + 'pach.svg'"
                width="95px"
                contain></v-img>
            </div>
          </div>
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <vuescroll
      :ops="ops">
      <v-list
        class="px-3"
        dense
        nav>
        <!-- <v-subheader
          class="mt-0">
          <span>Resumen</span>
        </v-subheader>
        <v-list-item
          active-class="primary--text"
          to="/dashboard"
          link>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'DashboardView') ? 'material-icons-outlined' : ''">
              grid_view
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-subheader>
          <span>Operación</span>
        </v-subheader>
        <v-list-item
          active-class="primary--text"
          to="/check-lists"
          link>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'CheckListsView') ? 'material-icons-outlined' : ''">
              checklist
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Check lists
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="primary--text"
          to="/maintance-logs"
          link>
          <v-list-item-icon>
            <v-icon
              :class="!$route.matched.some(({ name }) => name === 'MaintanceLogsView') ? 'material-icons-outlined' : ''">
              assignment
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Bitácoras
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </vuescroll>
    <template v-slot:[`append`]>
      <v-divider
        class="mb-1 mx-3">
      </v-divider>
      <v-list
        nav>
        <v-list-item
          class="v-list-item--active">
          <v-list-item-avatar>
            <v-avatar
              :color="session.avatar === null || session.avatar === '' ? session.color : ''"
              size="38">
              <img
                v-if="session.avatar !== null && session.avatar !== ''"
                :src="STATIC_IMG_AVATAR + session.avatar + '.svg'">
              <span
                v-else
                class="text-h6 font-weight-regular grey--text text--lighten-4">
                {{ session.avatarText }}
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              :class="['proxima-nova font-weight-semibold', !$vuetify.theme.dark ? '' : 'grey--text text--lighten-4']"
              style="font-size: 0.94rem !important;">
              {{ session.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              :class="!$vuetify.theme.dark ? '' : 'grey--text text--lighten-1'">
              {{ session.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Plugins
import vuescroll from 'vuescroll'

// Mixins
import { defines, layout, theme, session } from '@/mixins/config'

export default {
  name: 'SidebarComponent',
  mixins: [defines, layout, theme, session],
  components: { vuescroll },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primaryDark'
    }
  },
  data: () => ({
    app: true,
    width: 270, // 290
    mini: false,
    miniWidth: 80, // 110
    drawer: false,
    expandOnHover: false,
    toolbar: {
      height: 75
    },
    ops: {
      vuescroll: {
        wheelScrollDuration: 250
      },
      scrollPanel: {
        speed: 0,
        scrollingX: false,
        easing: 'easeInQuint',
        initialScrollX: false
      },
      rail: {
        background: 'transparent',
        opacity: 5,
        size: '4px',
        specifyBorderRadius: false
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#ffffff', // #022958 #232323 #202b35 #060915
        opacity: 0.35,
        hoverStyle: false,
        specifyBorderRadius: false,
        minSize: 0.2,
        size: '4px',
        disable: false
      }
    },
    session: {
      supervisorID: null,
      color: null,
      avatar: null, // default
      avatarText: null,
      name: null,
      email: null
    }
  }),
  methods: {
    sessionSync () {
      const auth = JSON.parse(localStorage.getItem('supervisionapp'))
      const sessionDecrypt = JSON.parse(atob(auth.session))

      if (sessionDecrypt) {
        this.session.supervisorID = sessionDecrypt.supervisorID
        this.session.color = sessionDecrypt.color
        this.session.avatar = (sessionDecrypt.avatarID === '') ? null : sessionDecrypt.avatarID
        this.session.avatarText = sessionDecrypt.avatarText
        this.session.name = sessionDecrypt.name
        this.session.email = sessionDecrypt.email
      }
    },

    toggle () {
      // if (this.$vuetify.breakpoint.mobile) {
      //   this.drawer = !this.drawer
      // } else {
      //   this.mini = !this.mini
      //   this.expandOnHover = !this.expandOnHover
      //   localStorage.setItem('mini', this.mini)
      //   localStorage.setItem('expandOnHover', this.expandOnHover)
      // }
      this.drawer = !this.drawer
    }
  },
  watch: { },
  computed: { },
  mounted () {
    // if (!this.$vuetify.breakpoint.mobile) {
    //   this.mini = JSON.parse(localStorage.getItem('mini')) ?? false
    //   this.expandOnHover = JSON.parse(localStorage.getItem('expandOnHover')) ?? false
    // }

    this.sessionSync()
  },
  created () { },
  destroyed () { }
}

</script>
