<template>
  <!-- <v-system-bar
    class="dark"
    height="35"
    fixed
    app>
    Hola
  </v-system-bar> -->
  <v-app-bar
    :app="app"
    :color="!$vuetify.theme.dark ? '' : ''"
    :class="$vuetify.breakpoint.mobile ? '' : ''"
    :height="!$vuetify.breakpoint.mobile ? height : 70"
    :hide-on-scroll="$vuetify.breakpoint.mobile ? false : false"
    align-content-xl="center"
    align-content-lg="center"
    align-content-md="center"
    align-content-sm="center"
    class="app-bar"
    elevation="0"
    clipped-right
    clipped
    outlined
    fixed>
    <v-container
      class="px-0 px-sm-0 w-100 fill-height">
      <v-app-bar-nav-icon
        v-if="toggleIcon"
        @click="toggler('sidebar')">
      </v-app-bar-nav-icon>
      <div
        class="d-flex fill-height align-center"
        :style="!$vuetify.breakpoint.mobile ? '' : ''">
        <div
          class="d-flex fill-height align-center">
          <v-toolbar-title
            v-if="brand"
            class="ml-1">
            <div class="brand d-flex align-center">
              <div
                :class="['brand--icon', !$vuetify.theme.dark ? 'primary--text' : 'grey--text text--lighten-4']">
                <v-img
                  :src="APP_IMG_BRAND + 'pach.svg'"
                  width="85px"
                  class="mx-auto">
                </v-img>
              </div>
              <div
                :class="['d-none brand--text font-weight-bold ml-3', !$vuetify.theme.dark ? 'primary--text' : 'grey--text text--lighten-4']">
                Shoppix
              </div>
            </div>
          </v-toolbar-title>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-menu
        v-if="createIcon"
        content-class="toggle-create"
        transition="scale-transition"
        origin="top right"
        color="white"
        offset-y
        bottom
        left>
        <template v-slot:[`activator`]="{ on: menu, attrs }">
          <v-tooltip
            v-if="$vuetify.breakpoint.mobile"
            v-model="tooltips.create"
            z-index="6"
            bottom>
            <template v-slot:[`activator`]="{ on: tooltip }">
              <v-btn
                @click.prevent="tooltips.create = !tooltips.create"
                v-on="{ ...tooltip, ...menu }"
                class="ml-2 ml-sm-2"
                v-bind="attrs"
                icon
                fab>
                <v-icon>
                  add
                </v-icon>
              </v-btn>
            </template>
            <span>Crear / Nuevo</span>
          </v-tooltip>
          <v-btn
            v-else
            class="btn-create mr-3"
            v-on="{ ...menu }"
            v-bind="attrs"
            depressed
            rounded
            text>
            Crear / Nuevo
          </v-btn>
        </template>
        <v-card
          rounded
          flat>
          <v-list
            dense
            nav>
            <v-subheader
              class="font-weight-medium">
              Crear / Nuevo
            </v-subheader>
            <v-list-item
              href="#/check-lists/create"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Lista
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="#/maintance-logs/create"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Bitácora
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-tooltip
        v-if="notificationIcon"
        v-model="tooltips.notifications"
        :open-on-click="false"
        :open-on-focus="false"
        z-index="6"
        bottom>
        <template v-slot:[`activator`]="{ on, attrs }">
          <v-badge
            :content="totalNotifications !== 0 ? totalNotifications.toString() : ''"
            :color="totalNotifications !== 0 ? 'accent' : 'transparent'"
            offset-x="15"
            offset-y="15"
            overlap>
            <v-btn
              @click.prevent="toggler('notifications')"
              class="ml-2 ml-sm-2"
              v-bind="attrs"
              v-on="on"
              small
              icon
              fab>
              <v-icon
                class="material-icons-outlined">
                notifications
              </v-icon>
              <!-- <span
                v-if="totalNotifications > 0"
                :class="['notification-badge rounded-circle pulse', !$vuetify.theme.dark ? 'danger' : 'secondary']"></span> -->
            </v-btn>
          </v-badge>
        </template>
        <span>Notificaciones</span>
      </v-tooltip>
      <!-- <v-menu
        content-class="toggle-help"
        transition="scale-transition"
        origin="top right"
        color="white"
        offset-y
        bottom
        left>
        <template v-slot:[`activator`]="{ on: menu, attrs }">
          <v-tooltip
            v-model="tooltips.help"
            :open-on-click="false"
            :open-on-focus="false"
            z-index="6"
            bottom>
            <template v-slot:[`activator`]="{ on: tooltip }">
              <v-btn
                @click.prevent="tooltips.help = !tooltips.help"
                v-on="{ ...tooltip, ...menu }"
                class="ml-2 ml-sm-2"
                v-bind="attrs"
                small
                icon
                fab>
                <v-icon
                  class="material-icons-outlined">
                  help_outline
                </v-icon>
              </v-btn>
            </template>
            <span>Ayuda</span>
          </v-tooltip>
        </template>
        <v-card
          rounded
          flat>
          <v-list
            dense
            nav>
            <v-subheader>
              Ayuda
            </v-subheader>
            <v-list-item
              href="mailto:support@blixsoft.com"
              target="_blank"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Obtener asistencia
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="toggler('about')"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Acerca de
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list
            dense
            nav>
            <v-subheader>
              Información legal
            </v-subheader>
            <v-list-item
              href="https://www.blixsoft.com/legal/privacy"
              target="_blank"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Aviso de privacidad
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              href="https://www.blixsoft.com/legal/end-user-agreement"
              target="_blank"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Acuerdo de licencia
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list
            dense
            nav>
            <v-subheader>
              Ayúdenos a mejorar
            </v-subheader>
            <v-list-item
              href="#/help/feedback"
              link>
              <v-list-item-content>
                <v-list-item-title>
                  Enviar comentarios
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->
      <v-menu
        content-class="toggle-profile"
        transition="scale-transition"
        origin="top right"
        elevation="24"
        color="white"
        offset-y
        bottom
        left>
        <template v-slot:[`activator`]="{ on }">
          <v-btn
            :color="!$vuetify.theme.dark ? '' : 'grey lighten-3'"
            class="ml-3"
            max-height="44"
            max-width="44"
            v-on="on"
            icon>
            <v-avatar
              :color="session.avatar === null || session.avatar === '' ? session.color : ''"
              size="40">
              <img
                v-if="session.avatar !== null && session.avatar !== ''"
                :src="STATIC_IMG_AVATAR + session.avatar + '.svg'">
              <span
                v-else
                class="text-h6 font-weight-regular grey--text text--lighten-4">
                {{ session.avatarText }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card
          rounded
          flat>
          <div class="avatar">
            <div class="d-block text-center mx-auto mb-3">
              <v-avatar
                :color="session.avatar === null || session.avatar === '' ? session.color : ''"
                class="overflow-hidden"
                size="60">
                <img
                  v-if="session.avatar !== null && session.avatar !== ''"
                  :src="STATIC_IMG_AVATAR + session.avatar + '.svg'">
                <span
                  v-else
                  class="text-h5 font-weight-regular grey--text text--lighten-4">
                  {{ session.avatarText }}
                </span>
              </v-avatar>
            </div>
            <div class="avatar--text d-block">
              <b>
                {{ session.name }}
              </b>
              <span
                class="d-block grey--text text--darken-4">
                {{ session.email }}
              </span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-list
            dense
            nav>
            <v-list-item
              href="#/account/profile"
              class="body-2"
              link>
              <v-list-item-icon>
                <v-icon
                  class="material-icons-outlined"
                  dense>
                  account_circle
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Mi perfil
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list
            dense
            nav>
            <v-list-item
              to="/auth/logout"
              class="body-2"
              link>
              <v-list-item-icon>
                <v-icon
                  class="material-icons-outlined"
                  dense>
                  logout
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Salir
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-container>
    <!-- </v-app-bar> -->
    <!-- Start Search -->
    <Search
      ref="msearch">
    </Search>
    <!-- End Search -->
    <!-- Start About -->
    <About
      ref="about">
    </About>
    <!-- End About -->
  </v-app-bar>
</template>

<script>
// Plugins
import axios from 'axios'

// Mixins
import { defines, layout, theme, session, auth } from '@/mixins/config'

// Components
import Search from '@/components/SearchComponent'
import About from '@/components/AboutComponent'

export default {
  name: 'NavComponent',
  mixins: [defines, layout, theme, session, auth],
  components: { Search, About },
  props: {
    toggleIcon: {
      type: Boolean,
      default: true
    },
    brand: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    searchIcon: {
      type: Boolean,
      default: true
    },
    createIcon: {
      type: Boolean,
      default: true
    },
    notificationIcon: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    app: true,
    height: 70,
    tooltips: {
      notifications: false,
      create: false,
      search: false,
      help: false,
      pos: false
    },
    totalNotifications: 0,
    ops: {
      vuescroll: {
        wheelScrollDuration: 250
      },
      scrollPanel: {
        speed: 0,
        scrollingX: false,
        easing: 'easeInQuint',
        initialScrollX: false
      },
      rail: {
        background: 'transparent',
        opacity: 5,
        size: '3px',
        specifyBorderRadius: false
      },
      bar: {
        showDelay: 500,
        onlyShowBarOnScroll: true,
        keepShow: false,
        background: '#022958', // #022958
        opacity: 0.4,
        hoverStyle: false,
        specifyBorderRadius: false,
        minSize: 0.2,
        size: '3px',
        disable: false
      }
    },
    session: {
      supervisorID: null,
      color: null,
      avatar: null, // default
      avatarText: null,
      name: null,
      email: null
    },
    create: {
      show: false
    },
    snack: {
      text: ''
    }
  }),
  methods: {
    sessionSync () {
      const auth = JSON.parse(localStorage.getItem('supervisionapp'))
      const sessionDecrypt = JSON.parse(atob(auth.session))

      if (sessionDecrypt) {
        this.session.supervisorID = sessionDecrypt.supervisorID
        this.session.color = sessionDecrypt.color
        this.session.avatar = (sessionDecrypt.avatarID === '') ? null : sessionDecrypt.avatarID
        this.session.avatarText = sessionDecrypt.avatarText
        this.session.name = sessionDecrypt.name
        this.session.email = sessionDecrypt.email
      }
    },

    handleScroll (event) {
      const header = document.querySelector('.app-bar')
      if (window.scrollY > 50) {
        header.classList.add('fixed')
        this.height = 65
      } else {
        header.classList.remove('fixed')
        this.height = 75
      }
    },

    toggler (target) {
      if (target === 'sidebar') {
        this.$emit('sidebar')
        // this.$refs.sidebar.toggle()
      } else if (target === 'search') {
        this.$emit('search')
      } else if (target === 'notifications') {
        this.totalNotifications = 0
        this.$emit('notifications')
      } else if (target === 'about') {
        this.$emit('about')
      }
    },

    // Checking Notifications
    async checkNotifications () {
      this.loading = true

      try {
        await axios
          .get(this.API + 'NotificationsAjax.php', {
            params: {
              module: 'notifications',
              request: 'check',
              supervisorID: this.session.supervisorID
            }
          })
          .then(response => {
            // console.log(response.data)

            this.loading = false

            if (response.data.status) {
              this.totalNotifications = response.data.total
            } else {
              this.snack.text = response.data.snack.text
              this.$emit('snack', this.snack)
            }
          })
          .catch(e => {
            this.loading = false

            this.snack.text = e
            this.$emit('snack', this.snack)
          })
      } catch (e) {
        this.loading = false

        this.snack.text = e
        this.$emit('snack', this.snack)
      }
    }
  },
  watch: { },
  computed: { },
  mounted () {
    this.sessionSync()
    // setInterval(this.checkNotifications, 10000) // 300000
  },
  created () {
    // window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    // window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
<style>

</style>
