<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>

export default {
  name: 'App',
  components: { },
  data: () => ({ }),
  methods: { },
  watch: { },
  computed: { },
  mounted () { },
  created () { },
  destroyed () { }
}

</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition-property: opacity visibility;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  visibility: hidden;
}

</style>
