<template>
  <v-navigation-drawer
    v-model="drawer"
    :color="$vuetify.breakpoint.mobile ? '' : ''"
    :width="$vuetify.breakpoint.mobile ? '100%' : '500px'"
    :overlay-opacity="$vuetify.breakpoint.mobile ? '0.6' : '0.35'"
    overlay-color="darkGrey"
    mobile-breakpoint="600"
    class="search"
    temporary
    touchless
    floating
    fixed
    right
    app>
    <template v-slot:[`prepend`]>
      <v-toolbar
        color="transparent"
        class="search-bar"
        height="70"
        flat>
        <v-form>
          <v-row
            class="no-gutters align-center">
            <!-- <v-col
              class="text-center"
              cols="1">
              <v-btn
                @click.prevent="toggle"
                width="38"
                height="38"
                icon>
                <v-icon>
                  arrow_back
                </v-icon>
              </v-btn>
            </v-col> -->
            <v-col
              class="d-flex align-center"
              cols="12">
              <v-btn
                @click.prevent="toggle"
                width="38"
                height="38"
                icon>
                <v-icon>
                  arrow_back
                </v-icon>
              </v-btn>
              <v-text-field
                v-model="text"
                :autofocus="drawer === true"
                placeholder="Buscar"
                color="transparent"
                hide-details
                solo
                flat>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <v-list
      two-line
      dense>
      <v-list-item
        link>
        <v-list-item-avatar>
          <v-avatar
            :color="!$vuetify.theme.dark ? 'success': 'grey darken-3'"
            size="38">
            <v-icon
              color="white"
              size="24">
              check
            </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Confirmación de pago
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-regular">
            Orden #00025 pagada
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            Hoy
          </v-list-item-action-text>
          <v-btn
            width="24"
            height="24"
            icon>
            <v-icon>
              chevron_right
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        link>
        <v-list-item-avatar>
          <v-avatar
            :color="!$vuetify.theme.dark ? 'indigo': 'grey darken-3'"
            size="38">
            <v-icon
              color="white"
              size="24">
              mail
            </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Nuevo mensaje
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-regular">
            Hola, necesito ayuda...
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            Hoy
          </v-list-item-action-text>
          <v-btn
            width="24"
            height="24"
            icon>
            <v-icon>
              chevron_right
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        link>
        <v-list-item-avatar>
          <v-avatar
            :color="!$vuetify.theme.dark ? 'orange': 'grey darken-3'"
            size="38">
            <v-icon
              color="white"
              size="24">
              person_add
            </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Nuevo cliente
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-regular">
            Se ha registrado un nuevo cliente
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            Hoy
          </v-list-item-action-text>
          <v-btn
            width="24"
            height="24"
            icon>
            <v-icon>
              chevron_right
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item
        link>
        <v-list-item-avatar>
          <v-avatar
            :color="!$vuetify.theme.dark ? 'pink': 'grey darken-3'"
            size="38">
            <v-icon
              color="white"
              size="24">
              close
            </v-icon>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Error en pago
          </v-list-item-title>
          <v-list-item-subtitle
            class="font-weight-regular">
            No se ha podido cobrar el pedido #552150
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>
            22/01/2021
          </v-list-item-action-text>
          <v-btn
            width="24"
            height="24"
            icon>
            <v-icon>
              chevron_right
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <template v-slot:[`append`]>
      <!-- <v-divider
        class="border-dashed">
      </v-divider>
      <v-list
        dense
        nav>
        <v-list-item
          class="primary--text text-center"
          link>
          <v-list-item-content>
            <v-list-item-title>
              Marcar como leídas
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </template>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'SearchComponent',
  // props: {
  //   show: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data: () => ({
    drawer: false,
    loading: false,
    text: '',
    sync: '',
    items: []
  }),
  methods: {
    toggle () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style>

.search {
  height: 100% !important;
}

.search-bar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.search-bar .v-toolbar__content {
  width: 100%;
  padding: 4px 8px;
}

.search-bar .v-form {
  width: 100%;
}

</style>
