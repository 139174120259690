<template>
  <v-app>
    <!-- Start Nav -->
    <Nav
      @notifications="toggleNotifications"
      @search="toggleSearch"
      @sidebar="toggleSidebar"
      @about="toggleAbout"
      :value="!this.$route.matched.some(({ name }) => name === 'ErrorView')"
      ref="nav"></Nav>
    <!-- End Nav -->
    <!-- <Search
      ref="search">
    </Search> -->
    <!-- Start Sidebar -->
    <Sidebar
      :value="!this.$route.matched.some(({ name }) => name === 'ErrorView')"
      ref="sidebar"></Sidebar>
    <!-- End Sidebar -->
    <!-- Start Notifications -->
    <Notifications
      @sheet="toggleSheet"
      @snack="toggleSnack"
      :value="!this.$route.matched.some(({ name }) => name === 'ErrorView')"
      ref="notifications"></Notifications>
    <!-- End Notifications -->
    <!-- Start Content -->
    <v-main
      class="pb-2">
      <transition name="fade">
        <router-view
          @sheet="toggleSheet"
          @snack="toggleSnack"
          @loading="toggleLoading"
          @sessionSync="sessionSync"></router-view>
      </transition>
    </v-main>
    <!-- End Content -->
    <!-- Start Footer -->
    <!-- <Footer
      ref="footer"></Footer> -->
    <!-- End Footer -->
    <!-- Start Loading -->
    <Loading
      :show="loading"
      ref="loading">
    </Loading>
    <!-- End Loading -->
    <!-- Start Ajax Alert -->
    <BottomSheet
      :color="sheet.color"
      :title="sheet.title"
      :icon="sheet.icon"
      :text="sheet.text"
      ref="sheet">
    </BottomSheet>
    <Snack
      :text="snack.text"
      ref="snack">
    </Snack>
    <!-- End Ajax Alert -->
  </v-app>
</template>

<script>

// Components
import Nav from '@/components/NavComponent'
// import Search from '@/components/SearchComponent'
import Sidebar from '@/components/SidebarComponent'
import Notifications from '@/components/NotificationsComponent'
// import Footer from '@/components/FooterComponent'
import Loading from '@/components/LoadingComponent'
import BottomSheet from '@/components/BottomSheetComponent'
import Snack from '@/components/SnackComponent'

export default {
  name: 'App',
  components: { Nav, Sidebar, Notifications, Loading, BottomSheet, Snack },
  data: () => ({
    nav: false,
    sidebar: false,
    notifications: false,
    footer: false,
    loading: false,
    sheet: {
      color: 'error',
      title: '¡Oops!',
      icon: 'info',
      text: ''
    },
    snack: {
      color: 'danger',
      title: '¡Oops!',
      icon: 'info',
      text: ''
    }
  }),
  methods: {
    toggleSidebar () {
      this.$refs.sidebar.toggle()
    },

    toggleSearch () {
      this.$refs.search.toggle()
    },

    toggleNotifications () {
      this.$refs.notifications.open()
    },

    toggleAbout () {
      this.$refs.footer.toggleAbout()
    },

    toggleLoading (loading) {
      this.loading = loading
      // this.$refs.loading.toggle()
    },

    toggleSheet (sheet) {
      this.sheet.color = sheet.color
      this.sheet.title = sheet.title
      this.sheet.icon = sheet.icon
      this.sheet.text = sheet.text
      this.$refs.sheet.toggle()
    },

    toggleSnack (snack) {
      this.snack.text = snack.text
      this.$refs.snack.toggle()
    },

    sessionSync () {
      this.$refs.nav.sessionSync()
      this.$refs.sidebar.sessionSync()
    }
  },
  watch: { },
  computed: { },
  mounted () { },
  created () { },
  destroyed () { }
}

</script>

<style>

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .27s;
}

.fade-enter-active {
  transition-delay: .27s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
  /* transform: translateY(10px); */
}

</style>
