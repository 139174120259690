<template>
  <router-view
    @sheet="toggleSheet"
    @snack="toggleSnack"
    @loading="toggleLoading"
    @sessionSync="sessionSync"></router-view>
</template>

<script>

export default {
  name: 'ViewContent',
  methods: {
    toggleSheet (sheet) {
      this.$emit('sheet', sheet)
    },

    toggleSnack (snack) {
      this.$emit('snack', snack)
    },

    toggleLoading (loading) {
      this.$emit('loading', loading)
    },

    sessionSync () {
      this.$emit('sessionSync')
    }
  }
}
</script>
