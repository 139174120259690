<template>
  <!-- <v-progress-linear
    v-if="loading"
    class="page-loading"
    color="primary"
    height="1"
    indeterminate
    fixed
    top>
  </v-progress-linear> -->
  <v-overlay
    :value="show"
    :dark="$vuetify.theme.dark"
    opacity="0.35">
    <v-row
      class="fill-height"
      justify="center"
      align="center">
      <v-col
        cols="12">
        <v-card
          :width="!$vuetify.breakpoint.mobile ? '185' : ''"
          style="border-radius: 16px;"
          class="text-center pa-4"
          elevation="6">
          <v-progress-circular
            :color="!$vuetify.theme.dark ? 'primary' : 'grey lighten-5'"
            class="mr-3"
            size="24"
            width="2"
            indeterminate>
          </v-progress-circular>
          <span
            class="text-body-2">
            Un momento...
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingComponent',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    visibility: false
  }),
  methods: {
    toggle () {
      this.visibility = !this.visibility
    }
  }
}

</script>
