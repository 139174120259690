import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// import { ColorPicker, ColorPanel } from 'one-colorpicker'
import vuetify from './plugins/vuetify'

// Custom Styles
import '@/assets/css/style.css'

// Vue.use(ColorPanel)
// Vue.use(ColorPicker)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
